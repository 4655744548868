import { useContext, useEffect } from "react";
import { io } from "socket.io-client";
import useAuth from "./useAuth";
import useRoom from "./useRoom";
import GameContext from "../context/GameContext";

function arraysEqual(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export default function useGame() {
  const { auth } = useAuth();
  const { room } = useRoom();
  const {
    socket,
    setSocket,
    cardsPosition,
    setCardsPosition,
    filedSize,
    setFieldSize,
    role,
    setRole,
    users,
    setUsers,
    person,
    setPerson,
    isYourTurn,
    setIsYourTurn,
    inspectorProofCards,
    setInspectorProofCards,
    messages,
    setMessages,
  } = useContext(GameContext);

  useEffect(() => {
    if (!socket && auth?.user?._id && auth.token && room?._id) {
      setSocket(
        io(process.env.REACT_APP_SERVER_SOCKET, {
          query: {
            userId: auth.user._id,
            roomId: room._id,
          },
          auth: {
            token: auth.token,
          },
          secure: true,
          transports: ["websocket"],
        }),
      );
    }
  }, [auth?.user, auth?.token, room, socket, setSocket]);

  useEffect(() => {
    if (socket) {
      console.log("socket established:", socket);
      socket.on("connect_error", (error) => {
        console.error("Socket.IO connection error:", error.message);
      });

      socket.on("disconnect", (reason) => {
        console.log("Socket.IO disconnected:", reason);
      });

      socket.on("game:users", (userList) => {
        if (!arraysEqual(userList, users)) {
          console.log("newUsersList:", userList);
          setUsers(userList);
        }
      });

      socket.on("game:cards_positions", (newCardsPosition) => {
        console.log("game:cards_positions");
        setCardsPosition(newCardsPosition);
      });

      socket.on("game:role", (newRole) => {
        console.log("game:role:", newRole);
        setRole(newRole);
      });

      socket.on("game:require_update_role", () => {
        socket.emit("game:get_role");
        socket.emit("game:get_person");
      });

      socket.on("game:person", (cardIndex) => {
        setPerson(cardIndex);
      });

      socket.on("game:inspector_proof_cards", (proofCards) => {
        console.log("game:inspector_proof_cards:", proofCards);
        setInspectorProofCards(proofCards);
      });

      socket.on("message:update", (newMessages) => {
        console.log("message:update", newMessages);
        setMessages(newMessages);
      });

      if (role) {
        socket.on("game:turn", (turn) => {
          const isYourTurn =
            role === "inspector" ? turn.inspector : turn.bandit;
          console.log("isYourTurn:", isYourTurn, "role:", role);
          setIsYourTurn(isYourTurn);
        });
      }
    }
  }, [socket]);

  useEffect(() => {
    console.log("game:person:", person);
  }, [person]);

  const moveCard = (cardId, direction) => {
    if (socket) {
      socket.emit("game:move_vector", cardId, direction);
    }
  };

  const chooseRole = (selectedRole) => {
    if (socket) {
      socket.emit("game:choose_role", selectedRole);
    }
  };

  const choosePersonFromDeck = (person) => {
    if (socket) {
      console.log("game:inspector_choose_person", person);
      socket.emit("game:inspector_choose_person", person);
    }
  };

  const disconnect = () => {
    if (socket) {
      socket.emit("game:leave");
      socket.disconnect();
    }
  };

  const sendMessage = (message) => {
    if (socket) {
      socket.emit("message:add", message);
    }
  };

  const getMessages = () => {
    if (socket) {
      socket.emit("message:get");
    }
  };

  return {
    users,
    moveCard,
    socket,
    cardsPosition,
    role,
    chooseRole,
    disconnect,
    person,
    isYourTurn,
    inspectorProofCards,
    choosePersonFromDeck,
    messages,
    sendMessage,
    getMessages,
  };
}
