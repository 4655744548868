const UsersList = ({ users }) => {
  return (
    <div className="users">
      {/* <div className="users-list item-list"> */}
      <ul>
        {/* {users.map((user) => { */}
        {/*   return ( */}
        {/*     <li key={user.id} className="user"> */}
        {/*       <p>{user.username}</p> */}
        {/*     </li> */}
        {/*   ); */}
        {/* })} */}
      </ul>
    </div>
  );
};

export default UsersList;
