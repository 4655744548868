import React from "react";
import useAuth from "../../hooks/useAuth";
import useGame from "../../hooks/useGame";

export default function MessageItem({ message }) {
  const { auth } = useAuth();
  const { users } = useGame();
  const isMyMessage = message.user === auth?.user?._id ? true : false;

  return (
    <li key={message._id} className="line">
      <div className={`message-body${isMyMessage ? " me" : ""}`}>
        {isMyMessage ? null : (
          <span className="auther">
            {users.find((user) => user.id === message.user)?.username}
          </span>
        )}
        <p className="message-content">{message.text}</p>
      </div>
    </li>
  );
}
