import useGame from "../../hooks/useGame";
import Card from "./Card";
import { CardHolder } from "./CardHolder";
import { Overlay } from "./Overlay";

export const ChoosePersonFromDeck = ({ changeEnableState }) => {
  const { inspectorProofCards, choosePersonFromDeck } = useGame();

  const cardClickHandler = (id) => {
    choosePersonFromDeck(id);
  };

  const onCloseButtonClick = () => {
    console.log("close ChoosePersonFromDeck component");
    changeEnableState(false);
  };

  return (
    <Overlay>
      <div className="choose-person-from-deck background-dim">
        <div className="close-button">
          <button
            type="button flex right"
            onClick={onCloseButtonClick}
            value="Close"
          >
            <img
              className="update-img filter-white flex center press-animation"
              src="/images/icons/x-mark.svg"
              alt="Закрыть"
            />
          </button>
        </div>

        <div className="flex center label">
          <p>Выберите новую личнсть</p>
        </div>
        <div className="cards-deck flex center">
          <CardHolder
            tagClass="horizontal center"
            cards={inspectorProofCards.map((index) => (
              <Card
                id={index}
                state={"checked"}
                size={1}
                onClick={(id) => cardClickHandler(id)}
              />
            ))}
          />
        </div>
      </div>
    </Overlay>
  );
};
