import useGame from "../../hooks/useGame";

export const IsYourTurn = () => {
  const { isYourTurn } = useGame();
  return (
    <div
      className={`rounded center-text is-your-turn${
        isYourTurn ? " your" : " not-your"
      }`}
    >
      {isYourTurn ? "Ваш ход" : "Не ваш ход"}
    </div>
  );
};
