import {
  HomePage,
  SignInPage,
  SignUpPage,
  GamePage,
  GamesListPage,
} from "../pages/index.js";
import { Route, Routes } from "react-router-dom";
import Redirect from "../pages/Redirect";
import RequireAuth from "../components/requireAuth.js";
import RequireRoom from "../components/requireRoom.js";
import RequireNoRoom from "../components/requireNoRoom.js";

const AppRoutes = () => (
  <Routes>
    <Route path="*" element={<Redirect path="/" />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/signin" element={<SignInPage />} />
    <Route path="/signup" element={<SignUpPage />} />

    <Route element={<RequireAuth allowedRoles={["user"]} />}>
      <Route element={<RequireRoom />}>
        <Route path="/game/*" element={<GamePage />} />
      </Route>
      <Route element={<RequireNoRoom />}>
        <Route path="/games_list" element={<GamesListPage />} />
      </Route>
    </Route>
  </Routes>
);

export default AppRoutes;
