import { useEffect, useState } from "react";

const InfoWindow = ({ text }) => {
  const [info, setInfo] = useState(text);

  useEffect(() => {
    setInfo(text);
  }, [text]);

  return (
    <div className="info-window">
      <p>{info}</p>
    </div>
  );
};

export default InfoWindow;
