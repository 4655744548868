import useGame from "../../hooks/useGame";

export const ChooseRole = () => {
  const { chooseRole } = useGame();

  return (
    <div className="center background-dim">
      <p>Choose your role</p>
      <input
        className="button"
        type="button"
        value="Bandit"
        onClick={() => chooseRole("bandit")}
      ></input>
      <input
        className="button"
        type="button"
        value="Inspector"
        onClick={() => chooseRole("inspector")}
      ></input>
    </div>
  );
};
