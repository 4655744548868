import React from "react";

export const HomePage = () => {
  return (
    <div className="home-page">
      <div className="home-content adaptive-container">
        <div className="banner">
          <img src="/images/homePage/noir_banner.png" alt="banner" />
          <p className="banner-text">NOIR</p>
        </div>
        <h1>Welcome to "Noir Shadows" - Uncover the Secrets in the Shadows</h1>

        <div className="center">
          <p>
            Step into the mysterious world of crime and deception with "Noir
            Shadows," the thrilling online adaptation of the classic noir card
            game. Immerse yourself in a world where every move can make or break
            your fate, and only the cleverest will survive the shadows.
          </p>

          <h2>About the Game</h2>
          <p>
            "Noir Shadows" brings the intensity and suspense of the tabletop
            card game to your screen. Play as a cunning bandit, out for blood
            and riches, or take on the role of a sharp-witted inspector,
            determined to bring justice to the darkened streets. With each turn,
            make strategic decisions, solve mysteries, and engage in a battle of
            wits where trust is a luxury and betrayal is the norm.
          </p>
          <h2>How to Play</h2>
          <h3>Sign Up or Log In</h3>

          <p>
            Create your account or log in to start your journey in the Noir
            Shadows universe.
          </p>
          <h4>Choose Your Role</h4>

          <p>
            Decide if you'll be the elusive bandit, leaving a trail of bodies
            and chaos, or the relentless inspector, pursuing the truth in the
            shadows.
          </p>
          <h4>Initiate the Investigation</h4>

          <p>
            As the bandit, your first move is a mandatory kill, setting the tone
            for the game. As the inspector, carefully examine evidence and
            deduce the identity of the elusive bandit.
          </p>
          <h4>Take Turns and Uncover Secrets</h4>
          <p>
            Players take turns executing actions, including murders,
            investigations, and attempts to blend into the shadows.
          </p>
          <h4>Achieve Your Objectives</h4>
          <p>
            Bandits aim to eliminate the inspector or eliminate 14 citizens on
            the field. Inspectors strive to capture the bandit.
          </p>
          <h4>Strategize and Survive</h4>

          <p>
            Employ various actions, manipulate the game board, and adapt your
            strategy to outsmart your opponents.
          </p>
        </div>
      </div>
    </div>
  );
};
