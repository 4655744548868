import { useEffect, useRef, useState } from "react";
import useRoom from "../../hooks/useRoom";

export const AddRoom = () => {
  const { addRoom, fetchRooms } = useRoom();
  const [roomName, setRoomName] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const nameInputRef = useRef(null);

  const isValid = (string) => {
    const isFieldEmpty = string === "";
    const isValidLength = string.length > 3 && string.length <= 20;
    if (isFieldEmpty) {
      return false;
    }
    if (!isValidLength) {
      return false;
    }

    return true;
  };

  const addRoomClickHandle = (event) => {
    event.stopPropagation();
    const name = nameInputRef.current.value;
    console.log("add name:", name);
    if (!isValid(name)) {
      nameInputRef.current.focus();
      return;
    }

    addRoom(name)
      .then(() => {
        fetchRooms();
        setRoomName("");
        setErrorMessage(null);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            setErrorMessage(err.response.data.message);
          }
        } else {
          setErrorMessage("Some error occurred adding room");
        }
      });
  };

  return (
    <div className="vertical">
      {errorMessage ? (
        <div className="error-container">
          <p>{errorMessage}</p>
        </div>
      ) : null}
      <div className="form add-room flex">
        <div className="input-box">
          <input
            type="text"
            onChange={(event) => setRoomName(event.target.value)}
            value={roomName}
            placeholder=""
            ref={nameInputRef}
            pattern=".{4,20}"
          ></input>
          <label>Add room</label>
        </div>
        <button className="button">
          <img
            className="arrow-img press-animation"
            src="/images/icons/add.svg"
            alt="Add"
            onClick={addRoomClickHandle}
          />
        </button>
      </div>
    </div>
  );
};
