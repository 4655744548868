import useGame from "../../hooks/useGame.js";
import { Board } from "./Board/Board";
import { ChooseRole } from "./ChooseRole";
import { useEffect, useState } from "react";
import { CardHolder } from "./CardHolder.jsx";
import Card from "./Card.jsx";
import { ChoosePersonFromDeck } from "./ChoosePersonFromDeck.jsx";

export const Game = () => {
  const { users, role, person, inspectorProofCards } = useGame();
  const [choosePersonFromDeckFlag, setChoosePersonFromDeckFlag] =
    useState(false);

  useEffect(() => {
    console.log("person:", person);
    setChoosePersonFromDeckFlag(false);
  }, [person]);

  return (
    <div className="game">
      {users.length === 2 ? (
        role ? (
          <div>
            {role === "inspector" ? (
              <button
                className="game-button"
                onClick={() => setChoosePersonFromDeckFlag(true)}
              >
                Новая личность
              </button>
            ) : null}
            <div className="flex center">
              <div className="oponent-cards vertical center">
                <CardHolder
                  cards={[
                    <Card state={"cover"} />,
                    <Card state={"inspector-cover"} />,
                  ]}
                />
              </div>
              <Board />
              <div className="your-cards vertical center">
                <CardHolder
                  cards={[
                    <Card state={"cover"} />,
                    <Card state={"bandit-cover"} />,
                    <Card
                      id={person !== null ? person : null}
                      state={"checked"}
                    />,
                  ]}
                />
              </div>
            </div>
            {role === "inspector" &&
            inspectorProofCards !== undefined &&
            inspectorProofCards?.length !== 0 ? (
              <div className="cards-deck flex center">
                {choosePersonFromDeckFlag ? (
                  <ChoosePersonFromDeck
                    changeEnableState={setChoosePersonFromDeckFlag}
                  />
                ) : null}
                <CardHolder
                  tagClass="horizontal center"
                  cards={inspectorProofCards.map((index) => (
                    <Card id={index} state={"checked"} />
                  ))}
                />
              </div>
            ) : null}
          </div>
        ) : (
          <ChooseRole />
        )
      ) : null}
    </div>
  );
};
