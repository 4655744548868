import React from "react";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import useGame from "../../hooks/useGame";

function Chat() {
  const { messages, sendMessage } = useGame();

  return (
    <div>
      <div className="chat-container">
        <div className="background"></div>
        <div className="scroll">
          <div className="messages-container center scroll">
            <MessageList messages={messages} />
          </div>
        </div>
        <footer className="footer">
          <MessageInput sendMessage={sendMessage} />
        </footer>
      </div>
    </div>
  );
}

export default Chat;
