import React, { useEffect, useState } from "react";
import { Game, GameBar } from "../../components";
import { useNavigate } from "react-router-dom";
import InfoWindow from "../../components/infoWindow";
import useRoom from "../../hooks/useRoom";
import useGame from "../../hooks/useGame";
import Chat from "../../components/Chat/Chat";

export const GamePage = () => {
  const { room, leaveRoom } = useRoom();
  const { users, role, getMessages } = useGame();
  const navigate = useNavigate();
  const [infoText, setInfoText] = useState("Loading..");

  useEffect(() => {
    let text;
    if (room) {
      if (room.player1 && room.player2) {
        text = null;
      } else if (room.player1 || room.player2) {
        text = "Waiting second player";
      } else {
        text = "You did not enter any room";
      }
    }
    setInfoText(text);
  }, [users, room]);

  const handleLeaveButton = async () => {
    await leaveRoom();
    navigate("/games_list");
    window.location.reload();
  };

  return (
    <>
      <GameBar
        room={room}
        role={role}
        users={users}
        onLeaveButtonClick={handleLeaveButton}
      />
      <InfoWindow text={infoText} />
      <div className="flex center">
        <Game />
        <Chat />
      </div>
    </>
  );
};
