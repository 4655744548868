import React, { useEffect, useState, useMemo } from "react";
import { Flipped } from "react-flip-toolkit";
import { cardsDataOrigin } from "./cardsDataOrigin";

const personCardsImgPath = "/images/cards/alive/";
const otherCardsImgPath = "/images/cards/alive/";

function Card({ id, state, size, onClick }) {
  const [path, setPath] = useState("");
  const imagePath = useMemo(() => {
    let _path = "";
    if (state === "alive" || state === "checked" || state === "dead") {
      if (!cardsDataOrigin[id]) {
        console.error(`Card with id ${id} not found.`);
        console.error(
          "cardsDataOrigin:",
          personCardsImgPath + cardsDataOrigin[0].img,
        );
        state = "cover";
        return `${process.env.PUBLIC_URL}/images/cards/cover.jpg`;
      }
      _path = `${process.env.PUBLIC_URL}/images/cards/${state}/${cardsDataOrigin[id].img}`;
    } else {
      _path = `${process.env.PUBLIC_URL}/images/cards/${state}.jpg`;
    }
    return _path;
  }, [id, state]);

  useEffect(() => {
    setPath(imagePath);
  }, [imagePath]);

  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };

  return (
    <Flipped flipId={`card-${id}`}>
      <div>
        <button className={`card ${state}`} onClick={handleClick}>
          <img
            className={`${size ? "size-" + size : ""}`}
            src={path}
            alt={`Card ${id}`}
          />
          {/* {id} */}
        </button>
      </div>
    </Flipped>
  );
}

export default Card;
