export const cardsDataOrigin = [
  {
    id: 0,
    name: "Barrin",
    img: "Barrin.jpg",
  },
  {
    id: 1,
    name: "Christoph",
    img: "Christoph.jpg",
  },
  {
    id: 2,
    name: "Clive",
    img: "Clive.jpg",
  },
  {
    id: 3,
    name: "Deidra",
    img: "Deidra.jpg",
  },
  {
    id: 4,
    name: "Elise",
    img: "Elise.jpg",
  },
  {
    id: 5,
    name: "Ernest",
    img: "Ernest.jpg",
  },
  {
    id: 6,
    name: "Franklin",
    img: "Franklin.jpg",
  },
  {
    id: 7,
    name: "Geneva",
    img: "Geneva.jpg",
  },
  {
    id: 8,
    name: "Horatio",
    img: "Horatio.jpg",
  },
  {
    id: 9,
    name: "Irma",
    img: "Irma.jpg",
  },
  {
    id: 10,
    name: "Julian",
    img: "Julian.jpg",
  },
  {
    id: 11,
    name: "Linus",
    img: "Linus.jpg",
  },
  {
    id: 12,
    name: "Marion",
    img: "Marion.jpg",
  },
  {
    id: 13,
    name: "Neil",
    img: "Neil.jpg",
  },
  {
    id: 14,
    name: "Ophelia",
    img: "Ophelia.jpg",
  },
  {
    id: 15,
    name: "Phoebe",
    img: "Phoebe.jpg",
  },
  {
    id: 16,
    name: "Quinton",
    img: "Quinton.jpg",
  },
  {
    id: 17,
    name: "Ryan",
    img: "Ryan.jpg",
  },
  {
    id: 18,
    name: "Simon",
    img: "Simon.jpg",
  },
  {
    id: 19,
    name: "Trevor",
    img: "Trevor.jpg",
  },
  {
    id: 20,
    name: "Ulysses",
    img: "Ulysses.jpg",
  },
  {
    id: 21,
    name: "Vladimir",
    img: "Vladimir.jpg",
  },
  {
    id: 22,
    name: "Wilhelm",
    img: "Wilhelm.jpg",
  },
  {
    id: 23,
    name: "Yvonne",
    img: "Yvonne.jpg",
  },
  {
    id: 24,
    name: "Zakari",
    img: "Zakari.jpg",
  },
];

export const otherData = [
  {
    name: "cover",
    img: "cover.jpg",
  },
  {
    name: "inspector",
    img: "inspector.jpg",
  },
  {
    name: "inspector_cover",
    img: "inspector_cover.jpg",
  },
  {
    name: "bandit_cover",
    img: "bandit_cover.jpg",
  },
  {
    name: "bandit_instruction",
    img: "bandit_instruction.jpg",
  },
];
