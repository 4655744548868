// In Board.jsx

import React, { useEffect, useState } from "react";
import Card from "../Card.jsx";
import { Arrow, withArrowDirection } from "../BoardArrow/BoardArrow.jsx";
import useGame from "../../../hooks/useGame.js";
import { Flipper, Flipped } from "react-flip-toolkit";

const ArrowRight = withArrowDirection(Arrow, "right");
const ArrowLeft = withArrowDirection(Arrow, "left");

export const Board = () => {
  const { cardsPosition, moveCard, isYourStep } = useGame();
  const [cards, setCards] = useState([]);
  const [flipKey, setFlipKey] = useState(0);

  const rowCount = 5;
  const columnCount = 5;

  useEffect(() => {
    if (cardsPosition) {
      const newCardsPosition = [...cardsPosition];
      setCards(generateCardRows(newCardsPosition));
      setFlipKey((prevKey) => prevKey + 1);
    }
  }, [cardsPosition]);

  function generateColumnButtons(direction) {
    const ArrowDirected = withArrowDirection(Arrow, direction);
    return (
      <tr className="column-arrow">
        <td key="empty" />
        {Array.from({ length: columnCount }, (_, i) => (
          <td key={i}>
            <ArrowDirected
              id={i}
              onClick={(param) => moveCard(param.id, param.direction)}
            />
          </td>
        ))}
      </tr>
    );
  }

  function generateCardRows(_cardsPosition) {
    return Array.from({ length: rowCount }, (_, i) => (
      <tr key={i}>
        <th>
          <ArrowLeft
            id={i}
            onClick={(param) => moveCard(param.id, param.direction)}
          />
        </th>
        {Array.from({ length: columnCount }, (__, j) => {
          const index = i * columnCount + j;
          return (
            <Flipped key={index} flipId={`card-${index}-${flipKey}`}>
              <td key={index}>
                <Card id={_cardsPosition[index]} state={"alive"} />
              </td>
            </Flipped>
          );
        })}
        <th>
          <ArrowRight
            id={i}
            onClick={(param) => moveCard(param.id, param.direction)}
          />
        </th>
      </tr>
    ));
  }

  return (
    <div className="board">
      <Flipper flipKey={flipKey}>
        {cards.length > 0 ? (
          <table>
            <tbody>
              {generateColumnButtons("up")}
              {cards}
              {generateColumnButtons("down")}
            </tbody>
          </table>
        ) : (
          <p>Loading...</p>
        )}
      </Flipper>
    </div>
  );
};
