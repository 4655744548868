import { IsYourTurn } from "../Game/IsYourTurn";
import { RoleIndicator } from "../Game/RoleIndicator";
import UsersList from "../UsersList/UsersList";

export const GameBar = ({ room, role, users, onLeaveButtonClick }) => {
  return (
    <div className="game-bar horizontal center">
      <div className="left-side horizontal center">
        <div className="room-name rounded center-text">{room?.name}</div>
        {role ? <RoleIndicator role={role} /> : null}
        <IsYourTurn />
        <UsersList room={room} />
      </div>
      <button
        className="game-button exit"
        onClick={onLeaveButtonClick}
        value="Leave this room"
      >
        Выйти из комнаты
      </button>
    </div>
  );
};
