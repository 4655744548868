import { useState } from "react";
import useRoom from "../../hooks/useRoom.js";
import { useNavigate } from "react-router-dom";
import { AddRoom } from "../Game/AddRoom.jsx";

const RoomsList = () => {
  const { rooms, fetchRooms, joinRoom } = useRoom();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleJoinRoom = async (roomId) => {
    try {
      await joinRoom(roomId);
      navigate("/game");
    } catch (err) {
      console.log("err.response.data.message:", err.response.data.message);
      setErrorMessage(
        err?.response ? err.response.data?.message : "No message",
      );
    }
  };

  const handleUpdateClick = () => {
    fetchRooms();
    setErrorMessage(null);
  };

  const handleAutoJoinClick = () => {
    let freeRoom;
    let availableRoom;
    rooms.every((room) => {
      if ((room.player1 && !room.player2) || (!room.player1 && room.player2)) {
        availableRoom = room;
        return false;
      }
      if (!freeRoom && !room.player1 && !room.player2) {
        freeRoom = room;
      }
      return true;
    });
    if (availableRoom) {
      handleJoinRoom(availableRoom._id);
    } else {
      if (freeRoom) {
        handleJoinRoom(freeRoom._id);
        return;
      } else {
        setErrorMessage("Can't find room");
      }
    }
  };

  return (
    <dev className="room-list">
      <div className="room-header flex">
        <h2 className="label">Games list</h2>
        <div className="flex tooltip">
          <span class="tooltiptext">Auto join</span>
          <button type="button" onClick={handleAutoJoinClick} value="Auto join">
            <img
              className="update-img flex center press-animation"
              src="/images/icons/magnifying-glass.svg"
              alt="Auto join"
            />
          </button>
        </div>
        <div className="flex tooltip">
          <span class="tooltiptext">Refresh</span>
          <button type="button" onClick={handleUpdateClick} value="Update List">
            <img
              className="update-img flex center press-animation"
              src="/images/icons/update.svg"
              alt="Update List"
            />
          </button>
        </div>
      </div>
      <AddRoom />
      {errorMessage ? (
        <div className="error-container">{errorMessage}</div>
      ) : null}
      <ul className="item-list">
        {rooms.map((room, index) => {
          return (
            <li key={index} className="horizontal ">
              <button
                className="join-room"
                onClick={() => handleJoinRoom(room._id)}
              >
                <img
                  className="flex center"
                  src="/images/icons/arrow-right-on-rectangle.svg"
                  alt="Join"
                />
              </button>
              <span className="room-name rounded">{room.name}</span>
              {room.player1 || room.player2 ? (
                <div className="horizontal users">
                  <div className="user">
                    {room.player1 ? <p>{room.player1.username}</p> : null}
                  </div>
                  <div className="user">
                    {room.player2 ? <p>{room.player2.username}</p> : null}
                  </div>
                </div>
              ) : null}
            </li>
          );
        })}
      </ul>
    </dev>
  );
};

export default RoomsList;
