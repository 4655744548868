import React from "react";
import RoomsList from "../../components/RoomsList/RoomsList";
import { AddRoom } from "../../components/Game/AddRoom";

export const GamesListPage = () => {
  return (
    <div className="vertical center">
      <RoomsList />
    </div>
  );
};
