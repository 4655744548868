import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Redirect from "../pages/Redirect";

const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();

  return auth?.user ? (
    auth.user.roles.find((role) => allowedRoles?.includes(role.name)) ? (
      <Outlet />
    ) : (
      <Redirect path="/signin" />
    )
  ) : (
    <Redirect path="/signin" />
  );
};

export default RequireAuth;
