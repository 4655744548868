import { createContext, useEffect, useState } from "react";

const GameContext = createContext(null);

export const GameProvider = ({ children }) => {
  const [socket, setSocket] = useState();
  const [cardsPosition, setCardsPosition] = useState();
  const [filedSize, setFieldSize] = useState({ row: 5, columnt: 5 });
  const [role, setRole] = useState();
  const [users, setUsers] = useState([]);
  const [person, setPerson] = useState();
  const [isYourTurn, setIsYourTurn] = useState(false);
  const [inspectorProofCards, setInspectorProofCards] = useState();
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (socket) {
      console.info("Socket is connected:", socket);
    } else {
      console.info("Socket is not connected");
    }
  }, [socket]);

  return (
    <GameContext.Provider
      value={{
        socket,
        setSocket,
        cardsPosition,
        setCardsPosition,
        role,
        setRole,
        users,
        setUsers,
        person,
        setPerson,
        filedSize,
        setFieldSize,
        isYourTurn,
        setIsYourTurn,
        inspectorProofCards,
        setInspectorProofCards,
        messages,
        setMessages,
      }}
    >
      {children}
    </GameContext.Provider>
  );
};

export default GameContext;
