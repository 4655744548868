import React from "react";

function withArrowDirection(WrappedComponent, direction) {
  return function WithArrowDirectino(props) {
    const arrowStyle = determineArrowStyle(direction);
    const arrowIdPre = `arrow_${direction}`;

    return (
      <WrappedComponent
        style={arrowStyle}
        alt={`Arrow ${direction}`}
        idPre={arrowIdPre}
        direction={direction}
        {...props}
      />
    );
  };
}

function Arrow({ style, id, alt, idPre, direction, onClick }) {
  // const [rowState, setRowState] = useState(row);

  const arrowId = `${idPre}_${id}`;

  function handleClick() {
    // id is the same as row or column
    onClick({ id, direction });
  }
  return (
    <div className="arrow">
      <button id={arrowId} onClick={handleClick}>
        <img
          className="arrow-img"
          style={style}
          src="/images/board/arrow.svg"
          alt={alt}
        />
      </button>
    </div>
  );
}

function determineArrowStyle(direction) {
  switch (direction) {
    case "up":
      return {
        transform: "rotate(-90deg)",
      };
    case "down":
      return {
        transform: "rotate(90deg)",
      };
    case "left":
      return {
        transform: "rotate(180deg)",
      };
    case "right":
      return {};
    default:
      return {};
  }
}

export { Arrow, withArrowDirection };
