import axios from "axios";
import { useEffect, useState } from "react";
import useAuth from "./useAuth";
import { getServerCookies } from "../utils/cookies";

export default function useRoom() {
  const { auth } = useAuth();
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState(getServerCookies("room"));

  useEffect(() => {
    if (auth?.user) {
      getRoom();
      fetchRooms();
    }
  }, [auth?.user]);

  const fetchRooms = () => {
    axios
      .get(process.env.REACT_APP_SERVER_API + "/room/list", { timeout: 5000 })
      .then((res) => {
        setRooms(res.data.rooms);
      })
      .catch((err) => {
        console.error(err.message || "An error occure while fetch room");
      });
  };

  const getRoom = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_SERVER_API + "/room/info",
      );
      if (response.status === 200) {
        setRoom(response.data.room);
      } else {
        throw new Error("An error occure while fetch room info");
      }
    } catch (error) {
      console.error(error.message || "An error occure while fetch room info");
    }
  };

  const addRoom = (name) => {
    const data = {
      name: name,
    };
    try {
      const res = axios.post(
        process.env.REACT_APP_SERVER_API + "/room/add",
        data,
      );
      fetchRooms();
      return res;
    } catch (err) {
      throw err;
    }
  };

  const joinRoom = async (roomId) => {
    await axios
      .post(process.env.REACT_APP_SERVER_API + "/room/" + roomId + "/join")
      .then((res) => {
        console.log("res.data.room:", res.data.room);
        setRoom(res.data.room);
      })
      .catch((err) => {
        throw err;
      });
  };

  const leaveRoom = async () => {
    const roomId = room._id;
    try {
      const response = await axios.post(
        process.env.REACT_APP_SERVER_API + "/room/" + roomId + "/leave",
      );
      console.log(response.data);
      if (response.status === 200) {
        setRoom(response.data.room);
      } else {
        throw new Error("An error occure while fetch room info");
      }
    } catch (error) {
      console.error(error.message || "An error occure while fetch room info");
    }
  };

  return { room, rooms, fetchRooms, joinRoom, getRoom, leaveRoom, addRoom };
}
