import { Outlet } from "react-router-dom";
import Redirect from "../pages/Redirect";
import useRoom from "../hooks/useRoom";

const RequireRoom = () => {
  const { room } = useRoom();

  return room ? <Outlet /> : <Redirect path="/game_list" />;
};

export default RequireRoom;
