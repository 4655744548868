import { Outlet } from "react-router-dom";
import Redirect from "../pages/Redirect";
import useRoom from "../hooks/useRoom";

const RequireNoRoom = () => {
  const { room } = useRoom();

  return !room ? <Outlet /> : <Redirect path="/game" />;
};

export default RequireNoRoom;
