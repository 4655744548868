import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

export const SignIn = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const nameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const navigate = useNavigate();
  const { auth, signin } = useAuth();

  useEffect(() => {
    nameInputRef.current.focus();
    if (auth?.user) {
      console.log("user:", auth);
      console.log("Authenticated");
    } else {
      console.log("Not Authenticated");
    }
  }, []);

  const isValid = (string) => {
    const isFieldEmpty = string === "";
    const hasWhiteSpace = /\s/g.test(string);
    const isValidLength = string.length > 3;
    if (isFieldEmpty) {
      return false;
    }
    if (hasWhiteSpace) {
      return false;
    }
    if (!isValidLength) {
      return false;
    }

    return true;
  };

  const onSubmit = (event) => {
    event.stopPropagation();
    const username = nameInputRef.current.value;
    const password = passwordInputRef.current.value;
    if (!isValid(username)) {
      nameInputRef.current.focus();
      return;
    }
    if (!isValid(password)) {
      passwordInputRef.current.focus();
      return;
    }

    signin(username, password)
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.data.message) {
            setErrorMessage(err.response.data.message);
          }
        } else {
          setErrorMessage("Произошла ошибка во время входа");
        }
      });
  };

  return (
    <div className="content">
      <div className="index-signin">
        <div className="form-header">
          <h2 className="center">Войти</h2>
        </div>
        <div className="form vertical">
          {errorMessage ? (
            <div className="error-container">
              <p>{errorMessage}</p>
            </div>
          ) : null}
          <div className="input-box">
            <input
              type="text"
              placeholder=""
              ref={nameInputRef}
              pattern="[_\-0-9A-z]{4,}"
            ></input>
            <label>Имя</label>
          </div>
          <div className="input-box">
            <input
              type="password"
              placeholder=""
              ref={passwordInputRef}
              pattern=".{4,}"
            ></input>
            <label>Пароль</label>
          </div>
          <input type="submit" value="Войти" onClick={onSubmit}></input>
          <input
            className="signup-btn"
            type="button"
            value="Зарегистрироваться"
            onClick={() => navigate("/signup")}
          ></input>
        </div>
      </div>
    </div>
  );
};
