import { useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";

export default function useAuth() {
  const { auth, setAuth } = useContext(AuthContext);

  const signout = async () => {
    axios
      .post(process.env.REACT_APP_SERVER_API + "/auth/signout")
      .then((res) => {
        setAuth({});
        return res;
      })
      .catch((err) => {
        console.log(err.message || "An error occuer while signout");
        return err;
      });
  };

  const signin = async (username, password) => {
    const data = {
      username: username,
      password: password,
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER_API + "/auth/signin",
        data,
      );
      const userData = res.data.user;
      const tokenData = res.data.token;
      const authData = { user: userData, token: tokenData };
      setAuth(authData);
      return res;
    } catch (err) {
      throw err;
    }
  };

  const signup = async (username, email, password, roles) => {
    const body = {
      username: username,
      email: email,
      password: password,
      roles: roles ? roles : ["user"],
    };
    try {
      const res = await axios.post(
        process.env.REACT_APP_SERVER_API + "/auth/signup",
        body,
      );
      setAuth(res.data);
      return res;
    } catch (err) {
      throw err;
    }
  };

  return {
    signin,
    signup,
    signout,
    auth,
  };
}
