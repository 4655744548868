import React, { useState, useEffect, useRef } from "react";

export default function MessageInput({ sendMessage }) {
  const [text, setText] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const inputRef = useRef();

  useEffect(() => {
    setSubmitDisabled(!text.trim());
  }, [text]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitDisabled) return;

    const message = text;
    sendMessage(message);

    setText("");
  };

  return (
    <form onSubmit={onSubmit} className="form message">
      <input
        className="message-input"
        type="text"
        autoFocus
        placeholder="Message"
        value={text}
        onChange={(e) => setText(e.target.value)}
        ref={inputRef}
      ></input>
    </form>
  );
}
