import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import useRoom from "../../hooks/useRoom.js";

const NavBar = () => {
  const { signout, auth } = useAuth();
  const { room } = useRoom();

  return (
    <header>
      <nav className="nav snow">
        <CustomLink to="/" className="site-title">
          NOIR
        </CustomLink>
        <ul>
          {auth?.user && room ? <CustomLink to="/game">Игра</CustomLink> : null}
          {auth?.user && !room ? (
            <CustomLink to="/games_list">Список Игр</CustomLink>
          ) : null}
          {auth?.user ? (
            <CustomLink to="/">{auth.user.username}</CustomLink>
          ) : null}
          {auth?.user ? (
            <CustomLink to="/signin" onClick={signout}>
              Выйти
            </CustomLink>
          ) : (
            <CustomLink to="/signin">Войти</CustomLink>
          )}
        </ul>
      </nav>
      <ul class="lightrope">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </header>
  );
};

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}

export { NavBar };
